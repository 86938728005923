
import { defineComponent, reactive, ref } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import DocumentEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = gpf.dataStatus();

    const titleTable: Array<string> = [
      "Banner",
      "Title",
      "Blog Kategori",
      "Blog Tag",
      "Status",
      "Created",
      "#",
    ];

    // "id": 1,
    // "slug": "adhigama-education-penipuan-simak-kebenarannya-berikut-ini",
    // "title": "Adhigama Education Penipuan? Simak Kebenarannya Berikut ini!",
    // "banner": "https://api.adhigama.education/img/aff2c81a0d525aed56c4afb52ce8a587.png",
    // "isActive": true,
    // "createdAt": "2021-04-21 22:32:06",
    // "BlogCategories": [
    //     {
    //         "id": 1,
    //         "title": "Info CPNS"
    //     },
    //     {
    //         "id": 2,
    //         "title": "Info PPPK"
    //     }
    // ],
    // "BlogTags": [
    //     {
    //         "id": 1,
    //         "title": "CPNS"
    //     },
    //     {
    //         "id": 2,
    //         "title": "PPPK"
    //     }
    // ]

    const dataBA = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingBAadmin").show();

      const hide = () => cash("#loadingBAadmin").hide();
      const page = dataBA.page;
      const pageSize = dataBA.pageSize;
      const search = dataBA.search;
      const url =
        api.adminBlogArtikel +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;
      // console.log("response get du ", rs);

      dataBA.data = rs;
      dataBA.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataBA.page = p;
      getData();
    };

    const nextPage = () => {
      dataBA.page = dataBA.page + 1;
      getData();
    };

    const beforePage = () => {
      dataBA.page = dataBA.page - 1;
      getData();
    };

    const lastPage = () => {
      dataBA.page = dataBA.paginate.totalPages;
      getData();
    };

    // BEGIN for CKEditor
    const date = ref("");
    const classicEditor = ClassicEditor;
    const inlineEditor = InlineEditor;
    const balloonEditor = BalloonEditor;
    const documentEditor = DocumentEditor;
    const simpleEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin,
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"],
      },
    };
    const editorConfig = {
      cloudServices: {
        tokenUrl: "",
        uploadUrl: "",
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImage,
        Heading,
        HeadingButtonsUI,
        Highlight,
      ],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight",
        ],
      },
    };
    const editorData = ref("<p>Content of the editor.</p>");
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };
    // END for CKEditor

    return {
      titleTable,
      getData,
      dataBA,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,

      // for CKEditor
      date,
      classicEditor,
      inlineEditor,
      balloonEditor,
      documentEditor,
      simpleEditorConfig,
      editorConfig,
      editorData,
      initDocumentEditor,
    };
  },
  data() {
    return {
      // for modal data bs
      section: "add",

      exDataBA: {
        id: 0,
        title: "",
        keyword: "",
        description: "",
        content: "",
        status: "1",
        kategori: [],
        tag: [],
        banner: "",
        nameBanner: "Choose File",
        oversizeBanner: false,
        tempBanner: "",
      },

      exDeleteBA: {
        id: 0,
        title: "",
      },

      listKategori: [],
      listTag: [],
    };
  },
  methods: {
    changeTS(v: any, f = "") {
      console.log("v multiple ", v);
      console.log("exDataBA ", this.exDataBA);

      const val = v.detail.args[1].key;
      const ex: any = this.exDataBA;
      ex[f] = val;
    },
    chooseBanner() {
      const id = "fileBannerDM";
      const btn: any = document.getElementById(id);
      btn.click();
    },
    removeBanner() {
      const ex = this.exDataBA;
      ex.nameBanner = "Choose File";
      ex.banner = "";
      cash("#showImage").attr("src", "");
    },
    handleFile(evt: any) {
      const target = evt.target;
      const files = target.files;
      const sizeFiles = target.files[0].size;
      const ex = this.exDataBA;

      if (sizeFiles > 1000000) {
        ex.oversizeBanner = true;
        this.removeBanner();
      } else {
        const nameFile = target.files[0].name;
        ex.nameBanner = nameFile;
        ex.banner = files[0];
        ex.oversizeBanner = false;
        // const bodyFormData = new FormData();
        // bodyFormData.append("files", files[0]);

        this.renderImage(target.files[0]);
      }
      // console.log("evt ", evt);
    },
    renderImage(files: any) {
      // console.log('masuk render image ', files)
      const reader = new FileReader();
      reader.onload = function (e: any) {
        cash("#showImage").attr("src", e.target.result);
      };
      reader.readAsDataURL(files);
    },
    async emptyField() {
      const ex = this.exDataBA;
      ex.title = "";
      ex.status = "1";
      ex.keyword = "";
      ex.description = "";
      ex.content = "";
      ex.banner = "";
      ex.status = "1";
      ex.kategori = [];
      ex.tag = [];
    },
    async callKategoriTag() {
      gpf.gLoading.show();

      const urlKategori = api.adminBlogKategori;
      const hitApiKategori = await api.hitApiGet(urlKategori);
      const rsKategori = hitApiKategori.data.response;
      this.listKategori = rsKategori;

      const urlTag = api.adminBlogTag;
      const hitApiTag = await api.hitApiGet(urlTag);
      const rsTag = hitApiTag.data.response;
      this.listTag = rsTag;

      gpf.gLoading.hide();
    },
    async addBA() {
      await this.emptyField();
      await this.callKategoriTag();

      this.section = "add";
      gpf.showModal("#modal-data-ba");
    },
    async addBAApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataBA;

      console.log("addBAApi ", ex);

      const params = new FormData();
      params.append("title", ex.title);
      params.append("keyword", ex.keyword);
      params.append("description", ex.description);
      params.append("content", ex.content);
      params.append("status", ex.status);
      params.append("banner", ex.banner);

      for (let i = 0; i < ex.kategori.length; i++) {
        params.append("kategori[]", ex.kategori[i]);
      }

      for (let i = 0; i < ex.tag.length; i++) {
        params.append("tag[]", ex.tag[i]);
      }

      const url = api.adminBlogArtikel;
      const hitApi = await api.hitApiPost(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-ba");
      await this.emptyField();
      await this.getData();
    },
    async editBA(data: any) {
      this.section = "edit";

      await this.callKategoriTag();

      const urlBA = api.adminBlogArtikel + data.id;
      const hitApi = await api.hitApiGet(urlBA);
      const rs = hitApi.data.response;

      const ex = this.exDataBA;
      ex.id = data.id;
      ex.title = rs.title;
      ex.keyword = rs.keyword ? rs.keyword : "";
      ex.description = rs.description ? rs.description : "";
      ex.content = rs.content ? rs.content : "";
      ex.banner = rs.banner;
      ex.status = rs.isActive ? "1" : "0";

      ex.kategori = rs.BlogCategories.map((e: any) => {
        return e.id.toString();
      });
      ex.tag = rs.BlogTags.map((e: any) => {
        return e.id.toString();
      });

      gpf.showModal("#modal-data-ba");
    },
    async editBAApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataBA;
      const params = new FormData();
      params.append("title", ex.title);
      params.append("keyword", ex.keyword);
      params.append("description", ex.description);
      params.append("content", ex.content);
      params.append("status", ex.status);
      params.append("banner", ex.banner);

      for (let i = 0; i < ex.kategori.length; i++) {
        params.append("kategori[]", ex.kategori[i]);
      }

      for (let i = 0; i < ex.tag.length; i++) {
        params.append("tag[]", ex.tag[i]);
      }

      const url = api.adminBlogArtikel + ex.id;
      const hitApi = await api.hitApiPut(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-ba");
      await this.emptyField();
      await this.getData();
    },
    deleteBA(data: any): void {
      const ex = this.exDeleteBA;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-ba");
    },
    async deleteBAApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exDeleteBA;

      const url = api.adminBlogArtikel + ex.id;
      const hitApi = await api.hitApiDel(url, hide);
      ex.id = 0;
      ex.title = "";

      await gpf.handleSds(hitApi);
      await gpf.hideModal("#delete-modal-data-ba");
      await this.getData();
    },
  },
});
